
var $ = window.$ = window.jQuery = require('../lib/jquery/jquery-3.2.1')

require('../lib/foundation-sites-6.4.3/dist/js/plugins/foundation.core')
require('../lib/foundation-sites-6.4.3/dist/js/plugins/foundation.util.keyboard')
require('../lib/foundation-sites-6.4.3/dist/js/plugins/foundation.util.box')
require('../lib/foundation-sites-6.4.3/dist/js/plugins/foundation.util.nest')
require('../lib/foundation-sites-6.4.3/dist/js/plugins/foundation.util.touch')
require('../lib/foundation-sites-6.4.3/dist/js/plugins/foundation.util.triggers')
require('../lib/foundation-sites-6.4.3/dist/js/plugins/foundation.util.mediaQuery')
require('../lib/foundation-sites-6.4.3/dist/js/plugins/foundation.util.motion')
require('../lib/foundation-sites-6.4.3/dist/js/plugins/foundation.dropdown')
require('../lib/foundation-sites-6.4.3/dist/js/plugins/foundation.dropdownMenu')
require('../lib/foundation-sites-6.4.3/dist/js/plugins/foundation.reveal')
require('../lib/foundation-sites-6.4.3/dist/js/plugins/foundation.offcanvas')

require('../lib/foundation-datepicker-1.5.6/js/foundation-datepicker')
require('../lib/foundation-datepicker-1.5.6/js/locales/foundation-datepicker.es')

$(document).ready(function () {
  $(document).foundation()

  $('.with-datepicker').fdatepicker({
    format: 'dd/mm/yyyy',
    language: 'es',
    weekStart: 1
  })
})

// Enviar automáticamente los formularios de filtro al realizar algún cambio.
$(document).ready(function () {
  $('.django-filter select').change(function () {
    var p = $(this).parents('form')
    if (p.length) {
      p[0].submit();
    }
  })
});

// Plugin para tiempo en ms
$(document).ready(function () {
  $('.with-time-ms').each(function () {
    var $el = $(this)
    $el.attr('type', 'hidden')
    $el.attr('type', 'hidden')
    var val = parseInt($el.val())
    var s = 0
    var m = 0
    var h = 0
    var ms = 0

    var $i_h = $('<input type="number" maxlength="2" class="input-group-field" value="' + h + '">')
    var $i_m = $('<input type="number" maxlength="2" class="input-group-field" value="' + m + '">')
    var $i_s = $('<input type="number" maxlength="2" class="input-group-field" value="' + s + '">')
    var $i_ms = $('<input type="number" maxlength="3" class="input-group-field" value="' + ms + '">')

    var $wg = $('<div class="input-group input-group-tiempo"></div>')
    $wg.append($i_h)
      .append($('<span class="input-group-label">:</span>'))
      .append($i_m)
      .append($('<span class="input-group-label">:</span>'))
      .append($i_s)
      .append($('<span class="input-group-label">.</span>'))
      .append($i_ms)
    // $el.parent().append($wg)
    $wg.insertBefore($el)

    var updateFromMs = function (val)  {
      if (val) {
        ms = val
        s = Math.floor(ms / 1000)
        m = Math.floor(s / 60)
        h = Math.floor(m / 60)
        ms = ms % 1000
        s = s % 60
        m = m % 60
        $i_h.val(h)
        $i_m.val(m)
        $i_s.val(s)
        $i_ms.val(ms)
      }
    }
    updateFromMs(val)
    $el.change(function () {
      updateFromMs(parseInt($(this).val()))
    })

    var updateMsVal = function () {
      $el.val(
        (parseInt($i_h.val() || 0) * 60 * 60 * 1000) +
        (parseInt($i_m.val() || 0) * 60 * 1000) +
        (parseInt($i_s.val() || 0) * 1000) +
        parseInt($i_ms.val() || 0)
      )
      $el.trigger('change')
    }
    $i_h.keyup(updateMsVal)
    $i_m.keyup(updateMsVal)
    $i_s.keyup(updateMsVal)
    $i_ms.keyup(updateMsVal)
  })
})

window.toggleSearchCanvas = function () {
  var id = document.getElementById('site-canvas-search')
  if (id.className === 'open') {
    id.className = ''
  } else {
    id.className = 'open'
    document.getElementById('input-canvas-search').value = ''
    window.setTimeout(function () {
      document.getElementById('input-canvas-search').focus()
    }, 500)
  }
  document.getElementById('site-blur').className = id.className
  return false
}

window.toggleCreateCanvas = function () {
  var id = document.getElementById('site-canvas-add')
  if (id.className === 'open') {
    id.className = ''
  } else {
    id.className = 'open'
  }
  document.getElementById('site-blur').className = id.className
  return false
}

var closeAllCanvas = function () {
  if (document.getElementById('site-blur').className === 'open') {
    document.getElementById('site-canvas-add').className = ''
    document.getElementById('site-canvas-search').className = ''
    document.getElementById('site-blur').className = ''
  }
}

document.onkeypress = function (evt) {
  evt = evt || window.event
  if (evt.keyCode === 27) { // escape key maps to keycode `27`
    closeAllCanvas()
  }
}
document.getElementById('site-blur').addEventListener("click",function (e) {
   closeAllCanvas()
},false)

// Menú en mobil
document.getElementById('btn_handburger').addEventListener("click",function (e) {
  var id = document.getElementById('main-nav')
  if (id.className.includes('open')) {
    id.className = id.className.replace('open', '').trim()
  } else {
    id.className += ' open'
  }
  return false
},false)


// Buscador principal
$(document).ready(function () {
  var $s = $('#site-canvas-search')
  var c = document.createElement('div')
  $s[0].appendChild(c)

  var navigateToEvent = function () {
    if (this.getAttribute('data-id')) {
      window.location = '/evento/' + this.getAttribute('data-id')
    }
  }

  var $i = $('#input-canvas-search')
  var currentRequest = null
  $i.on('keyup', function () {
    var str = $i.val()
    if (str.length === 0) {
      $(c).empty()
      return
    }
    currentRequest = jQuery.ajax({
      type: 'GET',
      data: 'q=' + str,
      url: '/api/1.0/search',
      beforeSend : function()    {
        if(currentRequest == null) {
          c.innerHTML = '...cargando...'
        } else {
          currentRequest.abort();
        }
      },
      success: function(data) {
        // Success
        // console.info(data)
        var u = document.createElement('ul')
        if (data && data.length) {
          data.forEach(function (r) {
            var li = document.createElement('li')
            li.innerHTML = r.nombre
            li.setAttribute('data-id', r.id)
            li.addEventListener('click', navigateToEvent)
            u.appendChild(li)
          })
          $(c).empty()
          c.appendChild(u)
        }
      },
      error:function(e){
        // Error
        // alert('Ocurrió un error de comunicación con el servidor')
      }
    })
  })
})

